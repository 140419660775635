import axios from 'axios';
import api from '../utils/api';

const API_URL = process.env.REACT_APP_SERVER_URL;
const API_BASE_URL = `${API_URL}/api/documents`;

export const documentService = {
  getDocuments: async (projectId) => {
    try {
      const response = await api.get(`${API_BASE_URL}/${projectId}/documents`);
      return response.data.documents;
    } catch (error) {
      console.error('Error fetching documents:', error.message);
      throw error;
    }
  },

  uploadDocument: async (projectId, formData) => {
    console.log('Uploading document for project:', projectId);
    try {
       const response = await api.post(`${API_BASE_URL}/${projectId}/uploadDocument`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Upload response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error uploading document:', error);
      throw error;
    }
  },

  updateDocument: async (projectId, documentId, updateData) => {
    try {
      const response = await api.put(`${API_BASE_URL}/${projectId}/document/${documentId}`, updateData);
      return response.data;
    } catch (error) {
      console.error('Error updating document:', error.message);
      throw error;
    }
  },

  deleteDocument: async (projectId, documentId, userInfo) => {
    try {
      const userInfoString = encodeURIComponent(JSON.stringify(userInfo));
      await api.delete(`${API_BASE_URL}/${projectId}/document/${documentId}?userInfo=${userInfoString}`);
    } catch (error) {
      console.error('Error deleting document:', error.message);
      throw error;
    }
  },

  getSignedUrls: async (projectId, categories) => {
    try {
      const response = await api.get(`${API_BASE_URL}/${projectId}/signedUrls?category=${categories.join(',')}`);
      return response.data.urls;
    } catch (error) {
      console.error('Error fetching signed URLs:', error.message);
      throw error;
    }
  },

  sendApprovalReminder: async (projectId, documentId) => {
    try {
      const response = await api.post(`${API_BASE_URL}/${projectId}/document/${documentId}/sendApprovalReminder`);
      return response.data;
    } catch (error) {
      console.error('Error sending approval reminder:', error.message);
      throw error;
    }
  },

  addCommentToDocument: async (projectId, documentId, commentContent, userInfo) => {
    try {
      const response = await api.post(`${API_BASE_URL}/${projectId}/document/${documentId}/addComment`, { commentContent, userInfo });
      return response.data;
    } catch (error) {
      console.error('Error adding comment to document:', error.message);
      throw error;
    }
  },

  archiveDocument: async (projectId, documentId) => {
    try {
      const response = await api.post(`${API_BASE_URL}/${projectId}/document/${documentId}/archive`);
      return response.data;
    } catch (error) {
      console.error('Error archiving document:', error.message);
      throw error;
    }
  },

  unarchiveDocument: async (projectId, documentId) => {
    try {
      const response = await api.post(`${API_BASE_URL}/${projectId}/document/${documentId}/unarchive`);
      return response.data;
    } catch (error) {
      console.error('Error unarchiving document:', error.message);
      throw error;
    }
  }
};

export default documentService;

export const { 
  getDocuments, 
  uploadDocument, 
  updateDocument, 
  deleteDocument, 
  getSignedUrls, 
  sendApprovalReminder, 
  addCommentToDocument,
  archiveDocument,
  unarchiveDocument
} = documentService;
