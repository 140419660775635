import React from 'react';
import { Field, ErrorMessage } from 'formik';

const OnlineInfo = () => {
  return (
    <div className="border-b border-gray-900/10 pb-12">
      <h2 className="text-base font-semibold leading-7 text-gray-900">Online Information</h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">Please provide a link to the online information (e.g. Rightmove).</p>

      <div className="mt-10">
        <div className="w-full">
          <label htmlFor="onlineLink" className="block text-sm font-medium leading-6 text-gray-900">
            Rightmove / Online Link
          </label>
          <div className="mt-2">
            <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-[#2A9D8F]">
              <Field
                type="text"
                name="onlineLink"
                id="onlineLink"
                className="ml-2 block w-full border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="www.rightmove.co.uk/properties/12345678"
              />
            </div>
          </div>
          <ErrorMessage name="onlineLink" component="p" className="mt-2 text-sm text-red-600" />
        </div>
      </div>
    </div>
  );
};

export default OnlineInfo;
