import React, { useEffect, useState } from 'react';
import { Field, ErrorMessage, FieldArray, useFormikContext } from 'formik';

const PropertyInfo = ({ disableUpdateEmails }) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const [displayBudgets, setDisplayBudgets] = useState([]);

  // Function to format number with commas
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    const newDisplayBudgets = values.phases.map((phase) => {
      return phase.budget ? numberWithCommas(phase.budget) : '';
    });
    setDisplayBudgets(newDisplayBudgets);
  }, [values.phases]);

  const handleBudgetChange = (e, index) => {
    const numValue = parseInt(e.target.value.replace(/,/g, ''), 10);
    setFieldValue(`phases.${index}.budget`, isNaN(numValue) ? '' : numValue);
  };

  const handleWheel = (e) => {
    e.target.blur();
  };

  useEffect(() => {
    let numberOfPhases = values.numberOfPhases || 0;
    if (numberOfPhases < 1) {
      numberOfPhases = 1;
    }

    if (values.phases.length < numberOfPhases) {
      const diff = numberOfPhases - values.phases.length;
      for (let i = 0; i < diff; i++) {
        values.phases.push({ name: '', budget: '' });
      }
    } else if (values.phases.length > numberOfPhases) {
      values.phases = values.phases.slice(0, numberOfPhases);
    }
  }, [values.numberOfPhases]);

  return (
    <div className="border-b border-gray-900/10 pb-12">
      <h2 className="text-base font-semibold leading-7 text-gray-900">Property Information</h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">Please provide details about the property.</p>

      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-6">
          <label htmlFor="propertyAddress" className="block text-sm font-medium leading-6 text-gray-900">
            Property Address
          </label>
          <Field
            as="textarea"
            placeholder="Enter the property address here"
            name="propertyAddress"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="propertyAddress" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="projectStartDate" className="block text-sm font-medium leading-6 text-gray-900">
            Project Start Date *
          </label>
          <Field
            type="date"
            name="projectStartDate"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <p className="mt-1 text-xs text-gray-500 italic">Please indicate in this section when you would like to begin building works on site, if known.</p>
          <ErrorMessage name="projectStartDate" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="projectEndDate" className="block text-sm font-medium leading-6 text-gray-900">
            Target Project Finish Date *
          </label>
          <Field
            type="date"
            name="projectEndDate"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <p className="mt-1 text-xs text-gray-500 italic">Please indicate when you preference of project completion, if known.</p>
          <ErrorMessage name="projectEndDate" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">
            Property Type
          </label>
          <Field
            as="select"
            name="type"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          >
            <option value="">Select Type...</option>
            <option value="Detached">Detached</option>
            <option value="Semi-detached">Semi-detached</option>
            <option value="Terraced">Terraced</option>
            <option value="Flat">Flat</option>
            <option value="Bungalow">Bungalow</option>
            <option value="Commercial">Commercial</option>
          </Field>
          <ErrorMessage name="type" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="bedrooms" className="block text-sm font-medium leading-6 text-gray-900">
            Number of Bedrooms
          </label>
          <Field
            type="number"
            name="bedrooms"
            onWheel={handleWheel}
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="bedrooms" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="age" className="block text-sm font-medium leading-6 text-gray-900">
            Age of Property
          </label>
          <Field
            type="text"
            name="age"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="age" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="style" className="block text-sm font-medium leading-6 text-gray-900">
            Style of Property
          </label>
          <Field
            type="text"
            name="style"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="style" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-6">
          <label htmlFor="condition" className="block text-sm font-medium leading-6 text-gray-900">
            Property Condition
          </label>
          <Field
            as="textarea"
            placeholder="Enter the condition of the property here"
            name="condition"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="condition" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-6">
          <label htmlFor="totalBudget" className="block text-sm font-medium leading-6 text-gray-900">
            Total Budget
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">£</span>
            </div>
            <Field
              type="text"
              name="totalBudget"
              placeholder="0.00"
              className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
            />
          </div>
          <p className="mt-1 text-xs text-gray-500 italic">Please leave blank if the budget is not set.</p>
          <ErrorMessage name="totalBudget" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-6">
          <label htmlFor="furtherBudgetInformation" className="block text-sm font-medium leading-6 text-gray-900">
            Further Budget Information
          </label>
          <Field
            as="textarea"
            placeholder="Enter further information on the budget here"
            name="furtherBudgetInformation"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="furtherBudgetInformation" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-6">
          <label htmlFor="numberOfPhases" className="block text-sm font-medium leading-6 text-gray-900">
            Number of Phases
          </label>
          <Field
            as="select"
            name="numberOfPhases"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          >
            {[...Array(8)].map((e, i) => (
              <option key={i} value={i + 1}>
                {i + 1 === 1 ? `${i + 1} Phase` : `${i + 1} Phases`}
              </option>
            ))}
          </Field>
          <p className="mt-1 text-xs text-gray-500 italic">
            Do you plan to undertake this project in one or multiple phases? You may wish to complete part of the project.
          </p>
          <ErrorMessage name="numberOfPhases" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-6">
          <label htmlFor="phasesDescription" className="block text-sm font-medium leading-6 text-gray-900">
            Description of Phases
          </label>
          <Field
            as="textarea"
            placeholder="Enter the description of the phases here"
            name="phasesDescription"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="phasesDescription" component="div" className="text-xs text-red-500 italic" />
        </div>

        <div className="sm:col-span-6">
          <label htmlFor="projectDescription" className="block text-sm font-medium leading-6 text-gray-900">
            Additional Information
          </label>
          <Field
            as="textarea"
            placeholder="Please provide any additional information that you think may be useful for us to know."
            name="projectDescription"
            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
          />
          <ErrorMessage name="projectDescription" component="div" className="text-xs text-red-500 italic" />
        </div>
      </div>
    </div>
  );
};

export default PropertyInfo;
